<template>
  <div class="index">
    <div class="banner">
      <h2>首页</h2>
    </div>
    <div class="toTest"
         @click="toTestAction">
      开始体质测试
    </div>
    <div class="des">
      <div class="content-small">
        <div class="mark"></div>
        <p>体质说明</p>
        <div class="mark"></div>
      </div>

    </div>
    <div class="content">
      <div class="panel"
           v-for="(item,index) in testList"
           :key="'topic'+ index"
           @click="goDetail(item);">
        <div class="t">
          <img class="bg"
               :src="require('../assets/img/medical/bg/' + index + '.png')" />
        </div>
        <div class="cn">{{item.physique_name}}体质</div>
      </div>
    </div>
  </div>
</template>
<script>
import { http } from "../config";
export default {
  name: "medical",
  components: {},
  data () {
    return {
      testList: []
    };
  },
  mounted () {
    this.get();
    // ?applyid=NwmuHOtod9btK9F7WZmuMEOi3bJcFCxP&idcard=420104199302070419
    let query = this.$route.query;
    let applyid = query.applyid
    let idcard = query.idcard
    if (!applyid) {
      this.$toasted.show('缺少applyid');
      return
    }
    if (!idcard) {
      this.$toasted.show('缺少idcard');
      return
    }

  },
  methods: {
    get () {
      let query = this.$route.query;
      let url = `/cmpa/physique`;
      http.get(url, { params: query }).then(res => {
        this.testList = res.data;
      });
    },
    toTestAction () {
      let query = this.$route.query;
      this.$router.push({
        path: "/medicalbegin",
        query: { ...query }
      });
    },
    goDetail (item) {
      let query = this.$route.query;
      let id = item.id;
      query.id = id
      this.$router.push({
        path: "/medicaldetail",
        query: { ...query }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: PingFangSC;
  color: rgba(103, 103, 103, 1);
  -webkit-text-size-adjust: none;
}
.index {
  width: 7.5rem;
  margin: 0 auto;
  position: relative;
  height: 100%;
  background: rgba(249, 249, 249, 1);
  .banner {
    width: 100%;
    height: 3.36rem;
    background: url('../assets/img/medical/banner.png') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    h2 {
      font-size: 0.4rem;
      font-weight: 500;
      color: #fff;
      margin-top: 0.5rem;
    }
  }
  .toTest {
    position: absolute;
    top: 2.92rem;
    left: 0.3rem;
    right: 0.3rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 32px 0px rgba(226, 226, 226, 0.5);
    border-radius: 8px;
    color: rgba(0, 209, 183, 1);
    font-size: 0.4rem;
    font-family: PingFang SC;
  }
  .des {
    padding-top: 0.44rem;
    .content-small {
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(103, 103, 103, 1);
      }

      div.mark {
        width: 0.3rem;
        height: 0.2rem;
        margin: 0 0.24rem;
        background: url('../assets/img/medical/homeMark.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0.3rem;
    .panel {
      width: 2.18rem;
      height: 0.82rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 0.3rem 0px rgba(226, 226, 226, 0.5);
      border-radius: 0.08rem;
      margin-bottom: 0.4rem;
      overflow: hidden;
      font-size: 0.26rem;
      color: rgba(103, 103, 103, 1);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .t {
        display: flex;
        justify-content: space-between;
        margin-right: 0.14rem;
        .bg {
          width: 0.38rem;
          height: 0.4rem;
          vertical-align: middle;
        }
        .link {
          width: 0.05rem;
          height: 0.32rem;
          vertical-align: middle;
          background: url('../assets/img/medical/eclipse.png');
          background-size: 100% 100%;
        }
      }
      .en {
        font-size: 0.12rem;
        margin: 0.25rem 0 0.16rem 0;
      }
      .cn {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(103, 103, 103, 1);
      }
    }
  }
}
</style>
